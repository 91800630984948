import React from 'react'
import { useRef } from 'react';
import { Button, Col, Form, FormSelect, Modal, Row } from 'react-bootstrap';
import ReactSignatureCanvas from 'react-signature-canvas';
import products from '../data/products';
import db from '../services/firbaseConfig';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router';
import SignupForm from '../components/forms/SignupForm';

export default function Signup() {

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const emptyDetails = { name: "", email: "", phoneNumber: "", organization: "", mailingAddress: "", city: "", users: "", product: "", fax: "" };

    const [details, setDetails] = React.useState(emptyDetails);
    const [openModel, setOpenModel] = React.useState(false);
    const [showSignature, setShowSignature] = React.useState(false);
    const [signatureBinary, setSignatureBinary] = React.useState("");
    const [signed, setSigned] = React.useState(false);

    const sigCanvas = useRef({});
    const navigate = useNavigate()

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails({ ...details, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            setOpenModel(true);
        }
    }

    const clear = () => sigCanvas.current.clear();

    // const base64ToArrayBuffer = (base64) => {
    //     var finalBinary = str2bin.strToBin(base64);
    //     setImageBinary(finalBinary);
    //     return finalBinary;
    // };

    const save = () => {
        const alp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const base = alp.replace(/^data:image.+;base64,/, "");
        setSignatureBinary(alp);
        setSigned(true);
    };

    const saveForm = async (note) => {
        const data = {
            name: details.name,
            email: details.email,
            phoneNumber: details.phoneNumber,
            organization: details.organization,
            mailingAddress: details.mailingAddress,
            city: details.city,
            users: details.users,
            product: details.product,
            fax: details.fax,
            signature: signatureBinary,
            date: date
        }
        await addDoc(collection(db, 'SubscriptionSignup'), data);
        setOpenModel(false);
        setDetails(emptyDetails);
        setSignatureBinary("");
        setSigned(false);
        navigate('/thankyou', { state: { type: 2 } });
    };

    const closeModel = () => {
        setOpenModel(false);
        // setDetails(emptyDetails);
        setSignatureBinary("");
        setSigned(false);
    }

    return (
        <div data-elementor-type="wp-page" data-elementor-id="330" class="elementor elementor-330">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-7f7500f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" >
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8cd82ac" >
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-627be32"
                        data-id="627be32" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-6238231 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="6238231" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Sign Up</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-99ed5ea shadepro-sticky-no elementor-widget elementor-widget-text-editor" data-id="99ed5ea" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p style={{ textAlign: "center" }}>
                                        Optimum Health Information System
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-41fd038" data-id="41fd038" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-312b1eb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="312b1eb" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-38621ee"
                        data-id="38621ee" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0e03f95"
                        data-id="0e03f95" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-cbb6700 shadepro-sticky-no elementor-widget elementor-widget-shortcode"
                                data-id="cbb6700" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div role="form" class="wpcf7" id="wpcf7-f15388-p330-o1" lang="en-US" dir="ltr">
                                            <div class="screen-reader-response">
                                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                <ul></ul>
                                            </div>
                                            <SignupForm />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-c29d3e2"
                        data-id="c29d3e2" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}
