// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB7Lm4NZYhpn7kq41oidRHnQXI8dt5XDTw",
    authDomain: "optimus-da932.firebaseapp.com",
    projectId: "optimus-da932",
    storageBucket: "optimus-da932.appspot.com",
    messagingSenderId: "907012617902",
    appId: "1:907012617902:web:a7b11022cddbc2114a9f3f",
    measurementId: "G-XXL84RGJZF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export default db;