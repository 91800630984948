import React, { Suspense, useEffect } from 'react'
// import axios from '../assets/axios/axios'
// import axios from '../assets/axios/axios'
// import { useDispatch } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
// import StoresApi from '../assets/api/StoreApis'
// import { addStore } from '../redux/slices/storeSlice'
import RouteApp from './RouteApp'

export default function RouteIdentifier() {
    // const dispatch = useDispatch()
    // const getStore = () => {
    //     axios.get(`${StoresApi.StoreUrl}/GetStoreByCode/${"Padmlabs"}`)
    //         // axios.get(`${StoresApi.StoreUrl}/GetStoreFront/2`)
    //         .then(response => {
    //             dispatch(addStore(response.data.data))
    //             // getData(response.data.data.id)
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         })
    // }

    // useEffect(() => {
    //     getStore()
    // }, [])
    return (
        <Suspense>
            <Routes>
                <Route path={`/*`} element={<RouteApp />} />
            </Routes>
        </Suspense>
    )
}
