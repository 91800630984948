import React from 'react'
import "../assets/css/post-4307.css"
import "../assets/css/frontend-legacy.min.css"
import { Link } from 'react-router-dom'
import ScheduleDemoSection from '../components/ScheduleDemoSection'
import { Button, Modal } from 'react-bootstrap'
import SignupForm from '../components/forms/SignupForm'

export default function Saas() {

    const [openModel, setOpenModel] = React.useState(false);
    const [productId, setProductId] = React.useState("");

    const signUpProduct = (id) => {
        setProductId(id)
        setOpenModel(true)
    }

    return (
        <>
            <div data-elementor-type="wp-page" data-elementor-id="4307" class="elementor elementor-4307">
                <div class="elementor-inner">
                    <div class="elementor-section-wrap">
                        <section
                            class="elementor-section elementor-top-section elementor-element elementor-element-9539716 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                            data-id="9539716" data-element_type="section"
                            data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                            style={{ left: "0px" }}>
                            <div class="elementor-container elementor-column-gap-no">
                                <div class="elementor-row">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-45b44c6"
                                        data-id="45b44c6" data-element_type="column">
                                        <div class="elementor-column-wrap elementor-element-populated">
                                            <div class="elementor-widget-wrap">
                                                <section
                                                    class="elementor-section elementor-inner-section elementor-element elementor-element-ae33b2f elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                                    data-id="ae33b2f" data-element_type="section"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-row">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-016565d"
                                                                data-id="016565d" data-element_type="column">
                                                                <div
                                                                    class="elementor-column-wrap elementor-element-populated">
                                                                    <div class="elementor-widget-wrap">
                                                                        <div class="elementor-element elementor-element-42ef05d shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                                            data-id="42ef05d" data-element_type="widget"
                                                                            data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                            data-widget_type="heading.default">
                                                                            <div class="elementor-widget-container">
                                                                                <h2 class="elementor-heading-title elementor-size-default">Optimus.</h2>
                                                                                <h4>Health Information System</h4>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-element elementor-element-6ad73c5 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                                            data-id="6ad73c5" data-element_type="widget"
                                                                            data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                            data-widget_type="heading.default">
                                                                            <div class="elementor-widget-container">
                                                                                <h2 class="elementor-heading-title elementor-size-default">Serving over 250,000 patients </h2>
                                                                                <h2 class="elementor-heading-title elementor-size-default">
                                                                                    A small monthly subscription
                                                                                    No upfront payment
                                                                                    No setup fees*
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    class="elementor-section elementor-inner-section elementor-element elementor-element-af5b510 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                                    data-id="af5b510" data-element_type="section"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                                    <p class="text-center">
                                                        Get our limited time offer now
                                                        Valid till 30/4/2023
                                                    </p>
                                                    <div class="elementor-container elementor-column-gap-no">
                                                        <div class="elementor-row">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bc0a88f"
                                                                data-id="bc0a88f" data-element_type="column">
                                                                <div
                                                                    class="elementor-column-wrap elementor-element-populated">
                                                                    <div class="elementor-widget-wrap">
                                                                        <div class="elementor-element elementor-element-04daa42 shadepro-sticky-no elementor-widget elementor-widget-shadepro-price-table"
                                                                            data-id="04daa42" data-element_type="widget"
                                                                            data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                            data-widget_type="shadepro-price-table.default">
                                                                            <div class="elementor-widget-container">
                                                                                <div class="shadepro-pricing-area pricing-style-classic">
                                                                                    <div class="container-fluid">
                                                                                        <div class="row pricing-box-wrap justify-content-center"
                                                                                            id="shadepro-dynamic-deck"
                                                                                            data-pricing-dynamic=""
                                                                                            data-value-active="monthly">
                                                                                            <div
                                                                                                class="col-lg-4 col-md-6 col-12 shadepro-pricing-item-wrap">
                                                                                                <div
                                                                                                    class="shadepro-pricing-item ">
                                                                                                    <div
                                                                                                        class="shadepro-price-wrap">
                                                                                                        <span
                                                                                                            class="shadepro-pricing-title">Basic</span>
                                                                                                        <div
                                                                                                            class="shadepro-price shadepro-price-monthly">
                                                                                                            <h2 class="dynamic-value"
                                                                                                                data-active="19"
                                                                                                                data-monthly="100"
                                                                                                                data-yearly="49">
                                                                                                                <span
                                                                                                                    class="price-currency">QR</span>
                                                                                                            </h2>
                                                                                                            <span
                                                                                                                class="shadepro-pricing-duration dynamic-value"
                                                                                                                data-monthly="/ Limited time"
                                                                                                                data-yearly="/per year"></span>
                                                                                                            <span
                                                                                                                class="price-subtitle dynamic-value"
                                                                                                                data-active="billed monthly"
                                                                                                                data-monthly="Original Subscription /user QR140 "
                                                                                                                data-yearly="billed Yearly"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="shadepro-pricing-features">
                                                                                                        <p><strong>
                                                                                                            <img
                                                                                                                decoding="async"
                                                                                                                class="alignnone size-full wp-image-2867"
                                                                                                                src="/assets/images/Path.svg"
                                                                                                                alt="" />&nbsp;
                                                                                                            Appointment Calendar
                                                                                                        </strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Patient Billing</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Daily Cash Report</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Electronic Health Record</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Services Worklist</strong>
                                                                                                        </p>
                                                                                                        {/* <p><img decoding="async"
                                                                                                        class="alignnone size-full wp-image-2866"
                                                                                                        src="/assets/images/small-remove.svg"
                                                                                                        alt="" />&nbsp;
                                                                                                        Electronic Health Record</p>
                                                                                                    <p><img decoding="async"
                                                                                                        class="alignnone size-full wp-image-2866"
                                                                                                        src="/assets/images/small-remove.svg"
                                                                                                        alt="" />&nbsp;
                                                                                                        Services Worklist&nbsp;
                                                                                                    </p> */}
                                                                                                    </div>
                                                                                                    <div class="shadepro-btn-wrapper">
                                                                                                        <Link class="shadepro-btn monthly-btn btn-type-boxed w-100" to="#" onClick={() => signUpProduct("1")}>
                                                                                                            Signup
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    {/* <span
                                                                                                        class="pricing-bottom-info">No
                                                                                                        credit card
                                                                                                        required</span> */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-4 col-md-6 col-12 shadepro-pricing-item-wrap">
                                                                                                <div
                                                                                                    class="shadepro-pricing-item ">
                                                                                                    <div
                                                                                                        class="shadepro-price-wrap">
                                                                                                        <span
                                                                                                            class="shadepro-pricing-title">Advanced</span>
                                                                                                        <div
                                                                                                            class="shadepro-price shadepro-price-monthly">
                                                                                                            <h2 class="dynamic-value"
                                                                                                                data-active="19"
                                                                                                                data-monthly="135"
                                                                                                                data-yearly="49">
                                                                                                                <span
                                                                                                                    class="price-currency">QR</span>
                                                                                                            </h2>
                                                                                                            <span
                                                                                                                class="shadepro-pricing-duration dynamic-value"
                                                                                                                data-monthly="/ Limited time"
                                                                                                                data-yearly="/per year"></span>
                                                                                                            <span
                                                                                                                class="price-subtitle dynamic-value"
                                                                                                                data-active="billed monthly"
                                                                                                                data-monthly="Original Subscription /user QR185 "
                                                                                                                data-yearly="billed Yearly"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="shadepro-pricing-features">
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Basic Features</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Insurance and Coverage </strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Approval Management </strong>
                                                                                                        </p>
                                                                                                        <p><img decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            <strong>Insurance Claims</strong>
                                                                                                        </p>
                                                                                                        <p><img decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            <strong>QLM Integration </strong>
                                                                                                        </p>
                                                                                                        {/* <p><img decoding="async"
                                                                                                        class="alignnone size-full wp-image-2866"
                                                                                                        src="/assets/images/small-remove.svg"
                                                                                                        alt="" />&nbsp;
                                                                                                        &nbsp;QLM Integration </p> */}
                                                                                                    </div>
                                                                                                    <div class="shadepro-btn-wrapper">
                                                                                                        <Link class="shadepro-btn monthly-btn btn-type-boxed w-100" href="#" onClick={() => signUpProduct("2")}>
                                                                                                            Signup
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    {/* <span
                                                                                                        class="pricing-bottom-info">No
                                                                                                        credit card
                                                                                                        required</span> */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-4 col-md-6 col-12 shadepro-pricing-item-wrap">
                                                                                                <div
                                                                                                    class="shadepro-pricing-item ">
                                                                                                    <div
                                                                                                        class="shadepro-price-wrap">
                                                                                                        <span
                                                                                                            class="shadepro-pricing-title">Enterprise </span>
                                                                                                        <div class="shadepro-price shadepro-price-monthly" style={{ marginBottom: '19px' }}>
                                                                                                            <h2 class="dynamic-value"
                                                                                                                data-active="19"
                                                                                                                data-monthly=""
                                                                                                                data-yearly="49">
                                                                                                                <span
                                                                                                                    class="price-currency"></span>
                                                                                                            </h2>
                                                                                                            <span
                                                                                                                class="shadepro-pricing-duration dynamic-value"
                                                                                                                data-monthly="Contact us for the quote"
                                                                                                                data-yearly="/per year"></span>
                                                                                                            <span
                                                                                                                class="price-subtitle dynamic-value"
                                                                                                                data-active="billed monthly"
                                                                                                                data-monthly=""
                                                                                                                data-yearly="billed Yearly"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="shadepro-pricing-features">
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Pharmacy</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Lab</strong>
                                                                                                        </p>
                                                                                                        <p><strong><img
                                                                                                            decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            Radiology </strong>
                                                                                                        </p>
                                                                                                        <p><img decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            <strong>Inventory</strong>
                                                                                                        </p>
                                                                                                        <p><img decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            <strong>Banks</strong>
                                                                                                        </p>
                                                                                                        <p><img decoding="async"
                                                                                                            class="alignnone size-full wp-image-2867"
                                                                                                            src="/assets/images/Path.svg"
                                                                                                            alt="" />&nbsp;
                                                                                                            <strong>Accounts Payable </strong>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div class="shadepro-btn-wrapper">
                                                                                                        <Link class="shadepro-btn monthly-btn btn-type-boxed w-100" to="#" onClick={() => signUpProduct("3")}>
                                                                                                            Contact Us
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    {/* <span
                                                                                                        class="pricing-bottom-info">No
                                                                                                        credit card
                                                                                                        required</span> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>* Based on preparing the data by the client </p>
                                                </section>
                                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dda2761">
                                                    <div class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-92ebe09 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" >
                                                            <div class="elementor-widget-container">
                                                                <div class="shadepro-btn-wrapper enable-icon-box-no text-center">
                                                                    <Link class="shadepro-btn btn-type-boxed elementor-animation-float"
                                                                        to="/contact">
                                                                        Contact Us
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <section
                                                    class="elementor-section elementor-top-section elementor-element elementor-element-e3f5c83 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-adv-gradient-no shadepro-sticky-no"
                                                    data-id="e3f5c83" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div
                                                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7baad9d"
                                                            data-id="7baad9d" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    class="elementor-element elementor-element-946f381 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                                    data-id="946f381" data-element_type="widget"
                                                                    data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                    data-widget_type="heading.default">
                                                                    <div class="elementor-widget-container">
                                                                        <h2 class="elementor-size-default">Optimus. </h2>
                                                                        <h4 class="elementor-size-default">Health Information System</h4>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="elementor-element elementor-element-e5e94a1 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                                    data-id="e5e94a1" data-element_type="widget"
                                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                                                    <div class="elementor-widget-container">
                                                                        Why do you need Optimus. ? </div>
                                                                </div>

                                                                <section
                                                                    class="elementor-section elementor-inner-section elementor-element elementor-element-8c5ce7f elementor-reverse-mobile elementor-section-height-min-height elementor-section-boxed elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                                                    data-id="8c5ce7f" data-element_type="section"
                                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                                                    <div class="elementor-container elementor-column-gap-default">
                                                                        <div
                                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d77f717"
                                                                            data-id="d77f717" data-element_type="column">
                                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                                <div
                                                                                    class="elementor-element elementor-element-2a8b98e elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                                                    data-id="2a8b98e" data-element_type="widget"
                                                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                                    data-widget_type="icon-list.default">
                                                                                    <div class="elementor-widget-container">
                                                                                        <ul class="elementor-icon-list-items">
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Access securely from anywhere </span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Faster patient flow  </span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Accurate scheduling </span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Secure electronic medical records.</span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Complies with MOPH and insurance</span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Integrate with QLM</span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Control approvals and claim accuracy</span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Lower insurance rejections</span>
                                                                                            </li>
                                                                                            <li class="elementor-icon-list-item">
                                                                                                <span class="elementor-icon-list-icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                                                        <title>Group 6</title>
                                                                                                        <desc>Created with Sketch.</desc>
                                                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                                                        rx="8"></rect>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg> </span>
                                                                                                <span class="elementor-icon-list-text">Latest multi-tier technology</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d427345"
                                                                            data-id="d427345" data-element_type="column">
                                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                                <div
                                                                                    class="elementor-element elementor-element-5ec5014 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                                                    data-id="5ec5014" data-element_type="widget"
                                                                                    data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                                                    data-widget_type="image.default">
                                                                                    <div class="elementor-widget-container">
                                                                                        <img decoding="async"
                                                                                            src="/assets/images/Optimus-Modules-_EMAR.svg"
                                                                                            title="Optimus Modules -_Operation theater management"
                                                                                            alt="Optimus Modules -_Operation theater management" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal size='lg' show={openModel} dialogClassName="modal-90w">
                <Modal.Body>
                    <SignupForm productId={productId} />
                </Modal.Body>
                <Modal.Footer className='justify-content-start'>
                    <Button variant="secondary" onClick={() => setOpenModel(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
